export const REACT_APP_STAGE = process.env.REACT_APP_STAGE;
export const REACT_APP_API_VERSION = process.env.REACT_APP_API_VERSION;
export const REACT_APP_ENC_DEC_KEY = process.env.REACT_APP_ENC_DEC_KEY;
export const REACT_APP_ENC_DEC_IV = process.env.REACT_APP_ENC_DEC_IV;
export const REACT_APP_AWS_URL = process.env.REACT_APP_AWS_URL;

// QA API is for fallback
export const REACT_APP_API_URL = process.env.REACT_APP_API_URL || 'https://qa.propxchange.ca/api';
export const REACT_APP_RUM_CLIENT_IDENTITY_POOL =
  process.env.REACT_APP_RUM_CLIENT_IDENTITY_POOL || 'placeholder_pool';
export const REACT_APP_RUM_CLIENT_APPLICATION_ID =
  process.env.REACT_APP_RUM_CLIENT_APPLICATION_ID ||
  'placeholder_application_id';
export const REACT_APP_DATABASE_ENCRYPTION =
  process.env.REACT_APP_DATABASE_ENCRYPTION === 'true';
